import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import { adminSlice } from './adminauth';
import { usersSlice } from './usermanagement';
import { blogSlice } from './blogmanagement';

// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['admin']
};

const rootReducer = combineReducers({
  admin: adminSlice.reducer,
  users: usersSlice.reducer,
  blogs: blogSlice.reducer,
});

export default rootReducer;
