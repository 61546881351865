import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import {
  AdminDetails,
  changePassword,
  forgetpasswordAdmin,
  loginAdmin,
  registerAdmin,
  updatepasswordAdmin,
} from '.';
import { FormType } from '../../pages/Auth/Login';
import { toast } from 'react-toastify';

interface AccountState {
  admin: AdminDetails | null;
  error: string | null;
  pending: boolean | null | undefined;
}

const initialState: AccountState = {
  admin: null,
  error: '',
  pending: false,
};

export const signInAdmin = createAsyncThunk(
  'admin/signin',
  async (data: FormType, thunkAPI) => {
    try {
      const response = await loginAdmin(data);
      return response;
    } catch (error: any) {
      throw new Error(error?.data?.error || 'Something went wrong');
    }
  }
);
export const signUpAdmin = createAsyncThunk(
  'admin/signup',
  async (data: FormType, thunkAPI) => {
    try {
      const response = await registerAdmin(data);
      return response;
    } catch (error: any) {
      throw new Error(error?.data?.error || 'Something went wrong');
    }
  }
);
export const forgotPasswordAdmin = createAsyncThunk(
  'admin/forgotpassword',
  async (data: { email: string }, thunkAPI) => {
    try {
      const response = await forgetpasswordAdmin(data);
      return response;
    } catch (error: any) {
      throw new Error(error?.data?.error || 'Something went wrong');
    }
  }
);
export const updatePasswordAdmin = createAsyncThunk(
  'admin/updatepassword',
  async (
    data: {
      newPass: string;
      resetLink: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await updatepasswordAdmin(data);
      return response;
    } catch (error: any) {
      throw new Error(error?.data?.error || 'Something went wrong');
    }
  }
);
export const changePasswordAdmin = createAsyncThunk(
  'admin/changepassword',
  async (
    data: {
      newPassword: string;
      currentPassword: string;
      // email: string;
    },
    thunkAPI
  ) => {
    try {
      const response = await changePassword(data);
      window.location.href = '/';
      return response;
    } catch (error: any) {
      throw new Error(error?.data?.error || 'Something went wrong');
    }
  }
);

export const adminSlice = createSlice({
  name: 'admin/auth',
  initialState,
  reducers: {
    signOut: (state) => {
      state.admin = null;
      localStorage.removeItem('user');
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(signInAdmin.pending), (state) => {
        state.pending = true;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(signInAdmin.fulfilled),
        (state, action: PayloadAction<any>) => {
          toast.success(
            'Welcome Back ' + action.payload.result.user?.firstName,
            {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            }
          );
          console.log(action, "action")
          state.admin = action.payload.result;
          state.pending = false;
          state.error = null;
          Cookies.set('admin', action.payload.result.refreshToken.token);
        }
      )
      .addMatcher(isAnyOf(signInAdmin.rejected), (state, action) => {
        toast.error(action.error.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        state.pending = false;
        state.error = action.error.message as string;
      })
      .addMatcher(isAnyOf(signUpAdmin.pending), (state) => {
        state.pending = true;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(signUpAdmin.fulfilled),
        (state, action: PayloadAction<any>) => {
          toast.success('Welcome ' + action.payload.result.user?.firstName, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          state.admin = action.payload.result;
          state.pending = false;
          state.error = null;
          Cookies.set('admin', action?.payload?.result?.refreshToken?.token);
        }
      )
      .addMatcher(isAnyOf(signUpAdmin.rejected), (state, action) => {
        toast.error(action.error.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        state.pending = false;
        state.error = action.error.message as string;
      })
      .addMatcher(isAnyOf(forgotPasswordAdmin.pending), (state) => {
        state.pending = true;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(forgotPasswordAdmin.fulfilled),
        (state, action: PayloadAction<any>) => {
          toast.success(action.payload.message, {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          state.admin = action.payload.result;
          state.pending = false;
          state.error = null;
        }
      )
      .addMatcher(isAnyOf(forgotPasswordAdmin.rejected), (state, action) => {
        toast.error(action.error.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        state.pending = false;
        state.error = action.error.message as string;
      })
      .addMatcher(isAnyOf(updatePasswordAdmin.pending), (state) => {
        state.pending = true;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(updatePasswordAdmin.fulfilled),
        (state, action: PayloadAction<any>) => {
          toast.success('Password Reset Successfully', {
            position: 'top-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          state.admin = action.payload.result;
          state.pending = false;
          state.error = null;
        }
      )
      .addMatcher(isAnyOf(updatePasswordAdmin.rejected), (state, action) => {
        toast.error(action.error.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        state.pending = false;
        state.error = action.error.message as string;
      })
      .addMatcher(isAnyOf(changePasswordAdmin.pending), (state) => {
        state.pending = true;
        state.error = null;
      })
      .addMatcher(isAnyOf(changePasswordAdmin.fulfilled), (state, action) => {
        state.pending = false;
        state.error = null;
        toast.success(action.payload.message || 'Password has been changed', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      })
      .addMatcher(isAnyOf(changePasswordAdmin.rejected), (state, action) => {
        state.pending = false;
        state.error = null;
        toast.error(action.error.message || 'Failed to change the password', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      });
  },
});

export const { signOut } = adminSlice.actions;
