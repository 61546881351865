import Cookies from 'js-cookie';
import { apiService } from '../../services';

const getCookies = () => {
  return Cookies.get('admin');
};

export const getAllUsers = (): Promise<any> => {
  const cookies = getCookies();
  const headers = {
    Authorization: `Bearer ${cookies}`,
  };
  return apiService.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/users/getAllUser`,
    undefined,
    headers
  );
};
