import {
  NewsManagementIcon,
  AiJobboardIcon,
  AiToolsIcon,
  AdvertiseIcon,
  BlogManagementIcon,
} from '../assets';
import DashboardIcon from '../assets/DashboardIcon' 

export const sidebar = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    icon: (color: any) => DashboardIcon(color),
    url: 'dashboard',
  },
  {
    id: 'users',
    title: 'Users',
    icon: (color: any) => BlogManagementIcon(color),
    url: 'users',
  },
  {
    id: 'aitools',
    title: 'AI Tools',
    icon: (color: any) => AiToolsIcon(color),
    url: 'aitools ',
  },
  {
    id: 'blogmanagement',
    title: 'Blog Manage',
    icon: (color: any) => BlogManagementIcon(color),
    url: 'blogs ',
  },
  {
    id: 'newsmanage',
    title: 'News Manage',
    icon: (color: any) => NewsManagementIcon(color),
    url: 'news/view',
  },
  {
    id: 'aijobboard',
    title: 'Ai Job Board',
    icon: (color: any) => AiJobboardIcon(color),
    url: 'jobboard',
  },
  {
    id: 'advertise',
    title: 'Advertise',
    icon: (color: any) => AdvertiseIcon(color),
    url: 'advertise',
  },
];
