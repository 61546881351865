import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ButtonBase from '@mui/material/ButtonBase';
import { Box } from '@mui/material';
import { ActiveUsers, AnalyticsReport } from './Analytics';

export default function ComplexGrid() {
  return (
    <>
      <Box sx={{ width: '100%'  }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={7}>
            <Box sx={{ display: 'flex', justifyContent: 'left', gap: '20px' }}>
              <Paper
                sx={{
                  p: 2,
                  margin: 'auto',
                  MaxWidth: '760px',
                  height: '353px',
                  border: '1px solid #D1D4D7',
                  borderRadius: '12px',
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                <Box>
                  <AnalyticsReport />
                </Box>
              </Paper>
            </Box>
          </Grid>
          <Grid
            item
            xs={5}
            sx={{
              gap: '20px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'left', gap: '20px' }}>
              <Paper
                sx={{
                  p: 2,
                  margin: 'auto',
                  MaxWidth: '7403px',
                  height: '201px',
                  border: '1px solid #D1D4D7',
                  borderRadius: '12px',
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                <ActiveUsers />
              </Paper>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'left', gap: '20px' }}>
              <Paper
                sx={{
                  p: 2,
                  margin: 'auto',
                  MaxWidth: '403px',
                  height: '369px',
                  border: '1px solid #D1D4D7',
                  borderRadius: '12px',
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                {/* <Grid container spacing={2} sx={{ padding: '20px' }}> */}
                {/* <Blog /> */}
                {/* </Grid> */}
              </Paper>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'left', gap: '20px' }}>
              <Paper
                sx={{
                  p: 2,
                  margin: 'auto',
                  MaxWidth: '7403px',
                  height: '469px',
                  border: '1px solid #D1D4D7',
                  borderRadius: '12px',
                  flexGrow: 1,
                  backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
              >
                <Grid container spacing={2}>
                  <Grid item>
                    <ButtonBase sx={{ width: 128, height: 128 }}>
                      In progress
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm container direction="column">
                    {' '}
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs></Grid>
                      <Grid item></Grid>
                    </Grid>
                    <Grid item></Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
