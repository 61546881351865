import Cookies from 'js-cookie';
import { apiService } from '../../services';

const getCookies = () => {
  return Cookies.get('admin');
};

export const getAllBlogs = (): Promise<any> => {
  const cookies = getCookies();
  const headers = {
    Authorization: `Bearer ${cookies}`,
  };
  return apiService.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/blogs/admin/getAllBlog`,
    undefined,
    headers
  );
};
export const createNewBlog = (): Promise<any> => {
  const cookies = getCookies();
  const headers = {
    Authorization: `Bearer ${cookies}`,
  };
  return apiService.get(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/blogs/admin/getAllBlog`,
undefined,
    headers
  );
};
