import React from 'react';

const NewsManagementIcon = (color) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_701_6501)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 4C5.4775 4 1 7.91813 1 12.75C1 15.5119 2.46563 17.9713 4.75 19.5744V24L9.13063 21.3419C9.73688 21.4419 10.3606 21.5 11 21.5C16.5225 21.5 21 17.5825 21 12.75C21 7.91813 16.5225 4 11 4Z"
          fill="#3E3D98"
        />
      </g>
      <defs>
        <clipPath id="clip0_701_6501">
          <rect
            width="21.22"
            height="23"
            fill={color}
            transform="matrix(1 0 0 -1 0 23)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NewsManagementIcon;
