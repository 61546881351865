import React from 'react';

const Blogs = () => {
  return (
    <>
      <svg
        width="22"
        height="24"
        viewBox="0 0 22 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.00217 8.75H20.9979C20.9828 6.03121 20.8621 4.55883 19.9016 3.59835C18.8033 2.5 17.0355 2.5 13.5 2.5H8.5C4.96446 2.5 3.1967 2.5 2.09835 3.59835C1.13788 4.55883 1.0173 6.03121 1.00217 8.75ZM21 11.25H1V15C1 18.5355 1 20.3033 2.09835 21.4016C3.1967 22.5 4.96446 22.5 8.5 22.5H13.5C17.0355 22.5 18.8033 22.5 19.9016 21.4016C21 20.3033 21 18.5355 21 15V11.25Z"
          fill="#6B788E"
        />
      </svg>
    </>
  );
};

export default Blogs;
