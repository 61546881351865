import * as React from 'react';
import { useState, useEffect } from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import {
  DashboardBreadCrumb,
  Usermanagement,
  Blogs,
} from '../assets/breadcrumbs';
import { Box, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const getIconForSegment = (
  segment: string
): React.ReactElement | undefined => {
  switch (segment) {
    case 'users':
      return <Usermanagement />;
    case 'blogs':
      return <Blogs />;
    default:
      return undefined;
  }
};

export interface BreadCrumbProps {
  Icon: JSX.Element | undefined;
  title: string;
}

export default function BreadCrumbs(props: { breadcrumb: string[] }) {
  const [breadcrumbSegment, setBreadCrumbSegment] = useState<BreadCrumbProps[]>(
    []
  );

  const { breadcrumb } = props;
  useEffect(() => {
    const result = breadcrumb.map((title) => {
      const Icon = getIconForSegment(title);
      return { Icon, title };
    });
    setBreadCrumbSegment(result);
  }, [breadcrumb]);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Box
        sx={{
          width: '100%',
          padding: '10px 20px',
          borderRadius: '10px',
          display: 'flex',
          backgroundColor: '#F4F4FB',
          fontSize: '14px',
          fontWeight: '400',
        }}
      >
        <Link
          underline="hover"
          sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}
          color="inherit"
          href="/dashboard"
        >
          {<DashboardBreadCrumb />}
          Dashboard
          {<KeyboardArrowRightIcon />}
        </Link>
        {breadcrumbSegment.map((item, index) => {
          const { Icon, title } = item;
          return (
            <Link
              key={index}
              underline="hover"
              sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}
              color="inherit"
              href={`/${title}`}
            >
              {Icon}
              <Typography
                variant="body2"
                gutterBottom
                sx={{ textTransform: 'capitalize' }}
              >
                {title}
              </Typography>
              {<KeyboardArrowRightIcon />}
            </Link>
          );
        })}
      </Box>
    </Breadcrumbs>
  );
}
