import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { storageService } from './storage.service';
import Cookies from 'js-cookie';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.withCredentials = false;

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config: any) => {
  const token = storageService.getFromLocalStorage('token');

  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axios.interceptors.response.use(
  async (response: AxiosResponse) => response,
  (error: AxiosError) => {
    const { data, status } = error.response ?? {};
    switch (status) {
      case 400:
        toast.error(data?.message);
        break;
      case 401:
        console.log('Logout user...');
        break;
      case 403:
        toast.error('You are not allowed to do that!');
        break;
      case 500:
        toast.error(data?.message);
        break;
      default:
        break;
    }
    return Promise.reject(error.response);
  }
);

const multipartHeaders = {
  headers: { 'Content-type': 'multipart/form-data' },
};

const authHeaders = () => {
  const cookies = Cookies.get('admin');
  const headers = {
    Authorization: `Bearer ${cookies}`,
  };
  return headers;
};

export const apiService = {
  get: (url: string, params?: URLSearchParams, headers?: any) =>
    axios
      .get(url, { params, headers: { ...authHeaders(), ...headers } })
      .then(responseBody),
  post: (url: string, body: object, headers?: any) =>
    axios
      .post(url, body, { headers: { ...authHeaders(), ...headers } })
      .then(responseBody),
  put: (url: string, body: object, headers?: any) =>
    axios
      .put(url, body, { headers: { ...authHeaders(), ...headers } })
      .then(responseBody),
  patch: (url: string, body: object, headers?: any) =>
    axios
      .patch(url, body, { headers: { ...authHeaders(), ...headers } })
      .then(responseBody),
  delete: (url: string, headers?: any) =>
    axios
      .delete(url, { headers: { ...authHeaders(), ...headers } })
      .then(responseBody),
  postForm: (url: string, data: FormData) =>
    axios
      .post(url, data, {
        headers: { ...multipartHeaders.headers, ...authHeaders() },
      })
      .then(responseBody),
  putForm: (url: string, data: FormData) =>
    axios
      .put(url, data, {
        headers: { ...multipartHeaders.headers, ...authHeaders() },
      })
      .then(responseBody),
};

export function createFormData(item: Record<string, unknown>) {
  const formData: FormData = new FormData();
  for (const key in item) {
    formData.append(key, item[key] as string | Blob);
  }
  return formData;
}
