import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { avatarIcons } from '../assets';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { sidebar } from '../constants/constant';
import Avatar from '@mui/material/Avatar';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Menu, MenuItem } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../store';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
interface Props {
  handleModalOpen: VoidFunction;
  handleModalClose: VoidFunction;
  children: React.ReactNode;
}
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
const MiniDrawer: React.FC<Props> = (props: Props) => {
  const { handleModalOpen, children } = props;
  const theme = useTheme();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const admin = useAppSelector(state => state.admin)
  console.log(admin, 'admin')

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <CssBaseline />
      <AppBar
        sx={{
          backgroundColor: 'white',
          color: 'black',
        }}
        position="fixed"
        open={open}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            ...(open && { justifyContent: 'end' }),
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon sx={{ color: '#3E3D98' }} />
          </IconButton>
          <Box sx={{ ...(open && { float: 'right' }) }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={() => navigate('/changepassword')}>
                Change Password
              </MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={() => handleModalOpen()}>Log out</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              textAlign: 'left',
              marginLeft: '10px',
              color: '#3E3D98',
              fontFamily: 'Inter',
            }}
          >
            AI Tools
          </Typography>
        </DrawerHeader>
        <Divider />
        <List>
          <List
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {sidebar?.map((item, index) => (
              <ListItem
                key={item.id}
                disablePadding
                onClick={() => handleTabClick(index)}
                sx={{
                  padding: '0px 20px',
                  justifyContent: 'center',
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    backgroundColor:
                      activeTab === index ? '#3E3D98' : '',
                    color: activeTab === index ? 'white' : '#3E3D98',
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    borderRadius: '15px',
                  }}
                  onClick={() => navigate(item?.url)}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    {item.icon(activeTab === index ? 'white' : '#3E3D98')}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
            <ListItemButton
              sx={{
                minHeight: 62,
                backgroundColor: 'transparent',
                color: '#3E3D98',
                gap: '15px',
                justifyContent: 'space-between',
                borderRadius: '15px',
                background: '#F4F4FB',
                padding: '0px 10px',
                margin: '10px  0px',
              }}
            >
              <Avatar alt="Remy Sharp" src={avatarIcons} />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  gutterBottom
                  sx={{
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#2A3547',
                  }}
                >
                  {admin?.admin?.user?.firstName} {admin?.admin?.user?.lastName}
                </Typography>
                <Typography variant="body2" gutterBottom>
                  {admin?.admin?.user?.role === 'super_admin' ? "Super Admin" : "Admin"}
                </Typography>
              </Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => handleModalOpen()}
              >
                <PowerSettingsNewIcon sx={{ color: '#3E3D98' }} />
              </IconButton>
            </ListItemButton>
          </List>
        </List>
      </Drawer>
      {open && (
        <Button
          onClick={handleDrawerClose}
          sx={{
            width: '4rem',
            height: '2.5rem',
            position: 'absolute',
            top: '65px',
            left: '222px',
            borderRadius: '34px',
            background: ' #3E3D98',
          }}
        >
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon sx={{ color: 'white' }} />
          ) : (
            <CloseIcon sx={{ color: 'white' }} />
          )}
        </Button>
      )}

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          flexDirection: 'column',
          p: 3,
          display: 'flex',
          gap: '2rem',
        }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};
export default MiniDrawer;
