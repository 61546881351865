import React from 'react';

const Usermanagement = () => {
  return (
    <>
      <svg
        width="21"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.8889 17.3333H12.5556M15.7222 14.1667V20.5M2 20.5C2 16.4192 5.30812 13.1111 9.38889 13.1111C10.1225 13.1111 10.8311 13.218 11.5 13.4171M13.6111 5.72222C13.6111 8.05409 11.7207 9.94444 9.38889 9.94444C7.05702 9.94444 5.16667 8.05409 5.16667 5.72222C5.16667 3.39035 7.05702 1.5 9.38889 1.5C11.7207 1.5 13.6111 3.39035 13.6111 5.72222Z"
          stroke="#6B788E"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default Usermanagement;
