import { BrowserRouter } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Router } from '../../routes';
import { ToastContainer } from 'react-toastify';
import { Suspense } from 'react';
import { CircularIndeterminate } from '../Layout';
import 'react-quill/dist/quill.snow.css';
import SnackbarProvider from '../../components/snackbar';

export default function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Suspense fallback={<CircularIndeterminate />}>
        <SnackbarProvider>
          <Router />
        </SnackbarProvider>
      </Suspense>
    </BrowserRouter>
  );
}
