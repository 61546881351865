// LogoutModal.tsx
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface Props {
  handleModalOpen: VoidFunction;
  handleModalClose: VoidFunction;
  logoutModalOpen: boolean;
}

const LogoutModal: React.FC<Props> = (props) => {
  const { handleModalOpen, handleModalClose, logoutModalOpen } = props;
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove('admin');
    handleModalClose();
    toast.success('You are logged out', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    navigate('/signin');
  };
  return (
    <React.Fragment>
      <Dialog
        open={logoutModalOpen}
        onClose={handleModalClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {' Are you sure you want to logout ?'}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleModalClose}
            sx={{ color: '#3E3D98' }}
          >
            No
          </Button>
          <Button
            onClick={() => handleLogout()}
            autoFocus
            sx={{ color: '#3E3D98' }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default LogoutModal;
