import React from 'react';

const AiToolsIcon = (color) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1005_6202)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.402 3.5643V5.87587H15.8561C16.7399 5.87587 17.4626 6.71989 17.4626 7.75211V10.1226H19.6468C19.8405 10.1226 20 10.307 20 10.5351V15.3407C20 15.567 19.8421 15.7533 19.6468 15.7533H17.4626V18.1238C17.4626 19.156 16.7399 20 15.8561 20H3.97461C3.09082 20 2.36816 19.156 2.36816 18.1238V15.7533H0.35319C0.157878 15.7533 0 15.5689 0 15.3426V10.5351C0 10.3089 0.157878 10.1226 0.35319 10.1226H2.36816V7.75402C2.36816 6.7218 3.09082 5.87777 3.97461 5.87777H9.42871V3.5643C8.80208 3.32478 8.35124 2.63853 8.35124 1.82682C8.35124 0.817413 9.05111 0 9.91536 0C10.7796 0 11.4795 0.817413 11.4795 1.82682C11.4795 2.63853 11.027 3.32478 10.402 3.5643ZM6.67806 15.1583H13.3203C13.6442 15.1583 13.9095 15.4681 13.9095 15.8464V16.1696C13.9095 16.5479 13.6442 16.8577 13.3203 16.8577H6.67806C6.35417 16.8577 6.08887 16.5479 6.08887 16.1696V15.8464C6.08887 15.4662 6.35417 15.1583 6.67806 15.1583ZM12.8092 9.04667C13.6833 9.04667 14.3929 9.87359 14.3929 10.8963C14.3929 11.9171 13.6849 12.744 12.8092 12.744C11.9336 12.744 11.2272 11.9171 11.2272 10.8963C11.2256 9.87359 11.9352 9.04667 12.8092 9.04667ZM7.19076 9.04667C8.06478 9.04667 8.77279 9.87359 8.77279 10.8963C8.77279 11.9171 8.06478 12.744 7.19076 12.744C6.31673 12.744 5.60872 11.9171 5.60872 10.8963C5.60872 9.87359 6.31673 9.04667 7.19076 9.04667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_1005_6202">
          <rect width="20" height="20" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AiToolsIcon;
