import React from 'react';

const DashboardBreadCrumb = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.29 13.42C8.6593 13.42 10.58 15.3407 10.58 17.71C10.58 20.0793 8.6593 22 6.29 22C3.9207 22 2 20.0793 2 17.71C2 15.3407 3.9207 13.42 6.29 13.42Z"
          fill="#6B788E"
        />
        <path
          d="M18.6695 22H16.7695C14.5895 22 13.4395 20.85 13.4395 18.67V16.77C13.4395 14.59 14.5895 13.44 16.7695 13.44H18.6695C20.8495 13.44 21.9995 14.59 21.9995 16.77V18.67C21.9995 20.85 20.8495 22 18.6695 22Z"
          fill="#6B788E"
        />
        <path
          d="M7.24 10.5703H5.34C3.15 10.5703 2 9.42031 2 7.24031V5.34031C2 3.15031 3.15 2.00031 5.33 2.00031H7.23C9.41 2.00031 10.56 3.15031 10.56 5.33031V7.23031C10.57 9.42031 9.42 10.5703 7.24 10.5703Z"
          fill="#6B788E"
        />
        <path
          d="M17.7099 1.99813C20.0792 1.99813 21.9999 3.91882 21.9999 6.28812C21.9999 8.65742 20.0792 10.5781 17.7099 10.5781C15.3406 10.5781 13.4199 8.65742 13.4199 6.28812C13.4199 3.91882 15.3406 1.99813 17.7099 1.99813Z"
          fill="#6B788E"
        />
      </svg>
    </>
  );
};

export default DashboardBreadCrumb;
