import { ReactNode, forwardRef } from 'react';
// icons
import { Icon } from '@iconify/react';
// @mui
import { Box } from '@mui/material';

interface Props {
  children?: ReactNode;
  icon: string;
  width: number | string;
  sx: object;
}
export type Ref = HTMLButtonElement;
// ----------------------------------------------------------------------

const Iconify = forwardRef<Ref, Props>(
  ({ icon, width = 20, sx, ...other }, ref) => (
    <Box
      ref={ref}
      component={Icon}
      icon={icon}
      sx={{ width, height: width, ...sx }}
      {...other}
    />
  )
);

Iconify.displayName = 'Iconify';

export default Iconify;
