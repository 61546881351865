import { useRoutes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
const AdminLogin = lazy(() => import('../pages/Auth/Login'));
const AdminPasswordReset = lazy(
  () => import('../pages/Auth/PasswordReset')
);
const AdminUpdatePassword = lazy(
  () => import('../pages/Auth/UpdatePassword')
);
const ChangePassword = lazy(() => import('../pages/Auth/ChangePassword'));
const AdminDashboard = lazy(() => import('../pages/Admin/Dashboard'));
const UserManagement = lazy(() => import('../pages/Users/UserManagement'));
const BlogsManagement = lazy(() => import('../pages/BlogsManagement/Blogs'));
const CreateBlogs = lazy(() => import('../pages/BlogsManagement/CreateBlog'));
const CreateUser = lazy(() => import('../pages/Users/CreateUser'));
const AiToolsManagement = lazy(() => import('../pages/AiToolsManagement/AiTools'));

import { Sidebar } from '../components';
import { CircularIndeterminate } from '../pages/Layout';
import { PrivateRoute } from '../components/ProtectedRoutes';
import NotFound from '../components/NotFound';
import { LogoutModal } from '../components/DashboardBody';

export default function Router() {
  const [logoutModalOpen, setlogoutModalOpen] = React.useState(false);
  const handleModalOpen = () => {
    console.log('Modal opened');
    setlogoutModalOpen(true);
  };

  const handleModalClose = () => {
    setlogoutModalOpen(false);
  };
  const element = useRoutes([
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <PrivateRoute>
            <Sidebar
              handleModalOpen={handleModalOpen}
              handleModalClose={handleModalClose}
            >
              <>
                <LogoutModal
                  handleModalOpen={handleModalOpen}
                  handleModalClose={handleModalClose}
                  logoutModalOpen={logoutModalOpen}
                />
                <AdminDashboard />
              </>
            </Sidebar>
          </PrivateRoute>
        </Suspense>
      ),
      children: [{ path: '/', element: <AdminDashboard /> }],
    },
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <PrivateRoute>
            <AdminLogin />
          </PrivateRoute>
        </Suspense>
      ),
      children: [{ path: '/signin', element: <AdminLogin /> }],
    },
    // {
    //   element: (
    //     <Suspense fallback={<CircularIndeterminate />}>
    //       <AdminSignup />
    //     </Suspense>
    //   ),
    //   children: [{ path: '/signup', element: <AdminSignup /> }],
    // },

    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <AdminPasswordReset />
        </Suspense>
      ),
      children: [{ path: '/forgotpassword', element: <AdminLogin /> }],
    },
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <AdminUpdatePassword />
        </Suspense>
      ),
      children: [{ path: '/resetpassword/:token', element: <AdminLogin /> }],
    },
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <PrivateRoute>
            <Sidebar
              handleModalOpen={handleModalOpen}
              handleModalClose={handleModalClose}
            >
              <>
                <LogoutModal
                  handleModalOpen={handleModalOpen}
                  handleModalClose={handleModalClose}
                  logoutModalOpen={logoutModalOpen}
                />
                <AdminDashboard />
              </>
            </Sidebar>
          </PrivateRoute>
        </Suspense>
      ),
      children: [{ path: '/dashboard', element: <AdminDashboard /> }],
    },
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <PrivateRoute>
            <Sidebar
              handleModalOpen={handleModalOpen}
              handleModalClose={handleModalClose}
            >
              <>
                <LogoutModal
                  handleModalOpen={handleModalOpen}
                  handleModalClose={handleModalClose}
                  logoutModalOpen={logoutModalOpen}
                />
                <BlogsManagement />
              </>
            </Sidebar>
          </PrivateRoute>
        </Suspense>
      ),
      children: [{ path: '/blogs', element: <BlogsManagement /> }],
    },

    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <PrivateRoute>
            <Sidebar
              handleModalOpen={handleModalOpen}
              handleModalClose={handleModalClose}
            >
              <>
                <LogoutModal
                  handleModalOpen={handleModalOpen}
                  handleModalClose={handleModalClose}
                  logoutModalOpen={logoutModalOpen}
                />
                <CreateBlogs />
              </>
            </Sidebar>
          </PrivateRoute>
        </Suspense>
      ),
      children: [{ path: '/blogs/create', element: <CreateBlogs /> }],
    },
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <PrivateRoute>
            <ChangePassword />
          </PrivateRoute>
        </Suspense>
      ),
      children: [{ path: '/changepassword', element: <ChangePassword /> }],
    },
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <PrivateRoute>
            <Sidebar
              handleModalOpen={handleModalOpen}
              handleModalClose={handleModalClose}
            >
              <>
                <LogoutModal
                  handleModalOpen={handleModalOpen}
                  handleModalClose={handleModalClose}
                  logoutModalOpen={logoutModalOpen}
                />
                <UserManagement />
              </>
            </Sidebar>
          </PrivateRoute>
        </Suspense>
      ),
      children: [{ path: '/users', element: <UserManagement /> }],
    },
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <PrivateRoute>
            <Sidebar
              handleModalOpen={handleModalOpen}
              handleModalClose={handleModalClose}
            >
              <>
                <LogoutModal
                  handleModalOpen={handleModalOpen}
                  handleModalClose={handleModalClose}
                  logoutModalOpen={logoutModalOpen}
                />
                <AiToolsManagement />
              </>
            </Sidebar>
          </PrivateRoute>
        </Suspense>
      ),
      children: [{ path: '/aitools', element: <AiToolsManagement /> }],

    },
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <PrivateRoute>
            <Sidebar
              handleModalOpen={handleModalOpen}
              handleModalClose={handleModalClose}
            >
              <>
                <LogoutModal
                  handleModalOpen={handleModalOpen}
                  handleModalClose={handleModalClose}
                  logoutModalOpen={logoutModalOpen}
                />
                <CreateUser />
              </>
            </Sidebar>
          </PrivateRoute>
        </Suspense>
      ),
      children: [{ path: '/users/create', element: <CreateUser /> }],
    },
    {
      element: (
        <Suspense fallback={<CircularIndeterminate />}>
          <NotFound />
        </Suspense>
      ),
      children: [{ path: '*', element: <NotFound /> }],
    },
  ]);
  return element;
}
