import {
  PayloadAction,
  createAsyncThunk,
  createSlice,
  isAnyOf,
} from '@reduxjs/toolkit';
import { IBlog, createNewBlog, getAllBlogs } from '.';
import { toast } from 'react-toastify';

interface AccountState {
  blogs: IBlog[];
  error: string | null;
  pending: boolean;
}

const initialState: AccountState = {
  blogs: [],
  error: '',
  pending: false,
};
export const fetchAllBlogs = createAsyncThunk('fetch', async () => {
  try {
    const response = await getAllBlogs();
    return response;
  } catch (error: any) {
    console.error('🚀 ~ error:', error);
    throw new Error(
      error?.data || error?.data?.error || 'Something went wrong'
    );
  }
});
export const createBlog = createAsyncThunk('fetch', async () => {
  try {
    const response = await createNewBlog();
    return response;
  } catch (error: any) {
    console.error('🚀 ~ error:', error);
    throw new Error(
      error?.data || error?.data?.error || 'Something went wrong'
    );
  }
});

export const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isAnyOf(fetchAllBlogs.pending), (state) => {
        state.pending = true;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(fetchAllBlogs.fulfilled),
        (state, action: PayloadAction<any>) => {
          state.blogs = action.payload.data;
          state.pending = false;
          state.error = null;
        }
      )
      .addMatcher(isAnyOf(fetchAllBlogs.rejected), (state, action) => {
        toast.error(action.error.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        state.pending = false;
        state.error = action.error.message as string;
      })
      .addMatcher(isAnyOf(createBlog.pending), (state) => {
        state.pending = true;
        state.error = null;
      })
      .addMatcher(
        isAnyOf(createBlog.fulfilled),
        (state, action: PayloadAction<any>) => {
          console.log('🚀 ~ action:', action);

          state.pending = false;
          state.error = null;
        }
      )
      .addMatcher(isAnyOf(createBlog.rejected), (state, action) => {
        toast.error(action.error.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
        state.pending = false;
        state.error = action.error.message as string;
      });
  },
});
