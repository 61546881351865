import { IAdmin } from '.';
import { apiService } from '../../services';

export const loginAdmin = (admin: IAdmin): Promise<any> =>
  apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/admin/login`,
    admin
  );
export const registerAdmin = (admin: IAdmin): Promise<any> =>
  apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/admin/signup`,
    admin
  );
export const forgetpasswordAdmin = (admin: { email: string }): Promise<any> =>
  apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/admin/forgetpassword`,
    admin
  );
export const updatepasswordAdmin = (admin: {
  newPass: string;
  resetLink: string;
}): Promise<any> =>
  apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/admin/resetpassword`,
    admin
  );
export const changePassword = (admin: {
  newPassword: string;
  currentPassword: string;
  // email: string;
}): Promise<any> =>
  apiService.post(
    `${process.env.REACT_APP_BACKEND_URL}/api/v1/auth/admin/changepassword`,
    admin
  );
