import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Graph, IconUsers, Pageviews } from '../../assets';
import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
    <Box sx={{ width: '100%', marginBottom:"2rem" }}>
      <Grid
        container
        rowSpacing={1}
        columns={{ xs: 4, sm: 8, md: 16 }}
        columnSpacing={{ xs: 1, sm: 2, md: 4 }}
      >
        <Grid item xs={3}>
          <Item sx={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
            <img src={Pageviews} alt="Pageviews" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: '22.97px',
                  fontWeight: '700',
                  color: '#354764',
                }}
              >
                6M+
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ fontSize: '16px', fontWeight: '400', color: '#354764' }}
              >
                PAGE VIEWS
              </Typography>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item sx={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
            <img src={IconUsers} alt="Pageviews" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: '22.97px',
                  fontWeight: '700',
                  color: '#354764',
                }}
              >
                4.7M+
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ fontSize: '16px', fontWeight: '400', color: '#354764' }}
              >
                Monthly Active Users
              </Typography>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item sx={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
            <img src={Pageviews} alt="Pageviews" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: '22.97px',
                  fontWeight: '700',
                  color: '#354764',
                }}
              >
               300K+
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ fontSize: '16px', fontWeight: '400', color: '#354764' }}
              >
             Monthly new tools
              </Typography>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item sx={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
            <img src={Graph} alt="Pageviews" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: '22.97px',
                  fontWeight: '700',
                  color: '#354764',
                }}
              >
                60%
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ fontSize: '16px', fontWeight: '400', color: '#354764' }}
              >
                DESKTOP USERS
              </Typography>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={3}>
          <Item sx={{ display: 'flex', justifyContent: 'left', gap: '10px' }}>
            <img src={Graph} alt="Pageviews" />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  fontSize: '22.97px',
                  fontWeight: '700',
                  color: '#354764',
                }}
              >
                60%
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ fontSize: '16px', fontWeight: '400', color: '#354764' }}
              >
                DESKTOP USERS
              </Typography>
            </Box>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
