import React from 'react';

const BlogManagementIcon = (color) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.00217 8.25H20.9979C20.9828 5.53121 20.8621 4.05883 19.9016 3.09835C18.8033 2 17.0355 2 13.5 2H8.5C4.96446 2 3.1967 2 2.09835 3.09835C1.13788 4.05883 1.0173 5.53121 1.00217 8.25ZM21 10.75H1V14.5C1 18.0355 1 19.8033 2.09835 20.9016C3.1967 22 4.96446 22 8.5 22H13.5C17.0355 22 18.8033 22 19.9016 20.9016C21 19.8033 21 18.0355 21 14.5V10.75Z"
        fill={color}
      />
    </svg>
  );
};

export default BlogManagementIcon;
