import {
  Grid,
  Typography,
  Box,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  styled,
  Paper,
} from '@mui/material';
import React from 'react';
import Stack from '@mui/material/Stack';
import { PieChart, PieItemIdentifier } from '@mui/x-charts';
import { FiberManualRecord } from '@mui/icons-material';
import { uparrow } from '../../assets';
export const AnalyticsReport = () => {
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid
          item
          xs={6}
          sx={{
            height: '353px',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: '600', fontSize: '18px', lineHeight: '21.78px' }}
          >
            Most Search Category{' '}
          </Typography>
          <PieClickNoSnap />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            height: '100%',
            display: 'flex ',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: '600',
              fontSize: '18px',
              color: '#3E3D98',
              lineHeight: '21.78px',
              textAlign: 'end',
              margin: '20px 10px',
            }}
          >
            View Full Report{' '}
          </Typography>
          <FlexDivider />
        </Grid>
      </Grid>
    </>
  );
};

const data2 = [
  { label: 'Ai Video Tools', value: 13500 },
  { label: 'AI Image tools', value: 1500 },
  { label: 'Productivity Tools', value: 1500 },
  { label: 'Other Tools', value: 1500 },
];
const data1 = [
  { label: '13500 tools used', value: 13500 },
  { label: '1500 tools used', value: 1500 },
  { label: '1500 tools used', value: 1500 },
  { label: '1500 tools used', value: 1500 },
];
const series = [
  {
    innerRadius: 100,
    outerRadius: 120,
    id: 'series-2',
    data: data2,
  },
];
const series2 = [
  {
    innerRadius: 50,
    outerRadius: 70,
    id: 'series-2',
    data: data2,
  },
];
const getColor = (label: string) => {
  switch (label) {
    case 'Ai Video Tools':
      return '#ff0000';
    case 'AI Image tools':
      return '#00ff00';
    case 'Productivity Tools':
      return '#0000ff';
    case 'Other Tools':
      return '#ffff00';
    default:
      return '#000000';
  }
};

export default function PieClickNoSnap() {
  const [itemData, setItemData] = React.useState<
    PieItemIdentifier | undefined | null
  >(undefined);

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={{ xs: 0, md: 4 }}
      sx={{ width: '100%' }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <PieChart
          series={series}
          width={400}
          height={300}
          slotProps={{
            legend: { hidden: true },
          }}
          onItemClick={(event, d) => setItemData(d)}
        />{' '}
      </Box>
    </Stack>
  );
}

export function FlexDivider() {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderColor: 'divider',
          borderRadius: 2,
          bgcolor: 'background.paper',
          color: 'text.secondary',
          '& svg': {
            m: 1,
          },
        }}
      >
        {' '}
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '21.78px',
            margin: '10px',
          }}
        >
          5,68,455{' '}
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '14.52px',
              margin: '10px',
            }}
          >
            Searches
          </Typography>
        </Typography>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            fontWeight: '600',
            fontSize: '18px',
            lineHeight: '21.78px',
            margin: '10px',
          }}
        >
          18,000{' '}
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: '400',
              fontSize: '12px',
              lineHeight: '14.52px',
              margin: '10px 0px',
            }}
          >
            Tools Used
          </Typography>
        </Typography>
        <Divider orientation="horizontal" variant="middle" />
      </Box>
      <Divider />

      <Box>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <List>
              {data2.map((item, index) => (
                <ListItem key={index}>
                  <ListItemIcon>
                    <FiberManualRecord
                      style={{
                        color: getColor(item.label),
                        margin: '0px 10px',
                        minWidth: 0,
                      }}
                    />
                  </ListItemIcon>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize: '10.93px',
                      margin: 0,
                      padding: 0,
                      color: '#000000',
                      lineHeight: '15.3px',
                    }}
                  >
                    {item.label}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={6}>
            <List>
              {data1.map((item, index) => (
                <ListItem key={index}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      whiteSpace: 'nowrap',
                      fontSize: '16px',
                      margin: 0,
                      padding: 0,
                      color: '#01222E66',
                      lineHeight: '19.36px',
                    }}
                  >
                    {item.label}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export const ActiveUsers = () => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  const [itemData, setItemData] = React.useState<
    PieItemIdentifier | undefined | null
  >(undefined);
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
        <Grid
          item
          xs={8}
          sx={{
            color: '#2A3547',
            fontWeight: '600',
            padding: '10px 30px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ fontSize: '18px' }}>
            Monthly Active User
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontSize: '23.73px', fontWeight: '600' }}
          >
            4.7M+
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              fontSize: '14px',
              gap: '8px',
            }}
          >
            <img src={uparrow} />
            <Typography variant="body1" gutterBottom sx={{ color: '#2A3547' }}>
              +9%
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ color: '#5A6A85' }}>
              last Month
            </Typography>
          </Box>
          <Typography variant="body1" gutterBottom sx={{ color: '#5A6A85' }}>
            2024
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <PieChart
              series={series2}
              width={200}
              height={200}
              slotProps={{
                legend: { hidden: true },
              }}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
              }}
              onItemClick={(event, d) => setItemData(d)}
            />{' '}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
