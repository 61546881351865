import React from 'react';
import { notfound } from '../assets';
import { Box } from '@mui/material';
const NotFound = () => {
  return (
    <Box
      component={'div'}
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={notfound}
        height={'70%'}
        width={'70%'}
        alt="404 not found"
        loading="lazy"
      />
    </Box>
  );
};

export default NotFound;
